import safelyJoinSlugs from '../../helpers/safelyJoinSlugs'
import { INT_REGEXP } from '../../next-config/regexp'
import { EntityElastic } from '../../types/entity'

// eslint-disable-next-line
const pageRegex = new RegExp(`stranica=${INT_REGEXP}`, 'gi')

const processItemUrl = (item: EntityElastic, asPath?: string, basePath?: string): string => {
    const itemHref =
        asPath?.split('?')?.[0] ||
        item?.href ||
        item?.sitemap?.href ||
        (item?.path && `/${safelyJoinSlugs(item?.path?.map(p => p.slug))}`) ||
        ''
    const url = item?.permalink || `${basePath || ''}${itemHref}`

    const paginationQueryParam = asPath?.match(pageRegex)?.[0]

    if (paginationQueryParam) {
        return `${url}?${paginationQueryParam}`
    }

    return url
}
export default processItemUrl
