import { DeviceType } from './device-type'

function closeFold(foldStack, wlWithFolds, index) {
    if (foldStack.length > 0) {
        const lastFold = foldStack.pop()
        wlWithFolds.push({
            type: 'fold',
            suffix: lastFold.suffix,
            props: {
                widgets: lastFold?.props?.widgets || []
            }, // @WARNING: ALAWYS imitate widget API no matter how F-ed it is atm, every recursive process depends on props.widgets API, and that's why I hate nested models (idoncev)
            key: `${index}-${lastFold.suffix}`
        })
    }
}

function processFoldData(widgetList) {
    const foldStack = [] // Stack to manage nested folds
    const wlWithFolds = [] // Array to maintain order and group content

    widgetList.forEach(({ Component, props, key, position }, index) => {
        const title = props?.title || ''
        const isFoldStart = title.startsWith('foldStart')
        const isFoldEnd = title.startsWith('foldEnd')

        if (isFoldStart) {
            // close any unclosed fold
            closeFold(foldStack, wlWithFolds, index)
            // Start a new fold
            const suffix = title.split(':')[1]?.trim().replace(/ /g, '_') || `cardsList-${index}`
            foldStack.push({
                type: 'fold',
                suffix,
                props: { widgets: [] }
            })
        } else if (isFoldEnd) {
            // Close the current fold
            closeFold(foldStack, wlWithFolds, index)
        } else {
            // Handle non-fold items
            // eslint-disable-next-line no-lonely-if
            if (foldStack.length > 0) {
                // If inside a fold, add to the current fold's content
                foldStack[foldStack.length - 1].props.widgets.push({ Component, props, key, position })
            } else {
                // No active fold, treat as individual items
                wlWithFolds.push({ Component, props, key, position })
            }
        }
    })

    // Close any remaining folds that were not properly ended
    while (foldStack.length > 0) {
        closeFold(foldStack, wlWithFolds, 'forceClose')
    }

    return wlWithFolds
}

export const getWidgetComponentsFromList = ({
    ComponentType = {},
    WidgetComponents = {},
    widgetList = [],
    entityList = [],
    device = DeviceType.MOBILE
}) => {
    const wlToFolds = widgetList
        .filter(widget => widget.device === device || widget.device === DeviceType.ANY)
        .map(({ key, position, component, responsive: responsive0, props = {}, device: widgetDevice }) => {
            const responsive = props.responsive || responsive0

            const widget = {
                key,
                Component: WidgetComponents[component],
                position,
                props
            }
            widget.props.device = widgetDevice
            if (component === ComponentType.GROUP) {
                widget.props.widgets = getWidgetComponentsFromList({
                    widgetList: widget.props.widgetList,
                    entityList,
                    device,
                    ComponentType,
                    WidgetComponents
                })
                return widget
            }

            if (responsive) {
                const itemsList = widget.props.preloadedItems || []

                const list = responsive[device] || responsive[DeviceType.ANY]

                widget.props.items = (list?.items || [])
                    .map(listItem => {
                        const item = itemsList.find(entity => entity.id === listItem.id)

                        if (!item) {
                            return null
                        }

                        return {
                            ...listItem,
                            key: item.id,
                            item
                        }
                    })
                    .filter(Boolean)
            }
            return widget
        })
    return processFoldData(wlToFolds)
}
export default getWidgetComponentsFromList
