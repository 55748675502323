import { EntityElastic } from '../../types/entity'
import { SeoEntityType } from '../../types/seo'
import { isMovie } from './isMovie'

const getItemStructuredDataType = (item: EntityElastic): SeoEntityType => {
    switch (item.type) {
        case 'article':
            return 'article'
        case 'gallery':
            return 'gallery'
        case 'video':
            return 'video'
        case 'sitemap':
            return 'sitemap'
        case 'show':
        case 'movie':
            return isMovie(item) ? 'movie' : 'show'
        default:
            return 'general'
    }
}
export default getItemStructuredDataType
