/* eslint-disable @typescript-eslint/ban-ts-comment */
import { BreadcrumbList, ListItem } from 'schema-dts'

const sitemapToBreadcrumbs = (sitemap: any[], basePath: string): ListItem[] => {
    if (sitemap?.[0]?.slug === 'naslovnica') {
        return [
            {
                // eslint-disable-next-line @typescript-eslint/naming-convention
                '@type': 'ListItem',
                position: 1,
                item: {
                    // eslint-disable-next-line @typescript-eslint/naming-convention
                    '@type': 'WebPage',
                    name: 'Naslovnica',
                    url: basePath,
                    // @ts-ignore
                    id: basePath // required by google
                }
            }
        ]
    }
    return [
        {
            title: 'Naslovnica'
        },
        ...(sitemap || [])
    ]?.map(({ title, slug }, index) => {
        if (slug) {
            basePath += `/${slug}`
        }
        return {
            // eslint-disable-next-line @typescript-eslint/naming-convention
            '@type': 'ListItem',
            position: index + 1,
            item: {
                // eslint-disable-next-line @typescript-eslint/naming-convention
                '@type': 'WebPage',
                name: title,
                url: basePath,
                id: basePath // required by google
            }
        } as ListItem
    })
}

const parseBreadcrumbs = (entity: any, basePath: string) =>
    sitemapToBreadcrumbs(entity?.path || entity?.taxons?.sitemap?.[0]?.path, basePath)

export default parseBreadcrumbs

export const generateBreadcrumbStructuredData: (props: {
    name: string
    sitemap: any
    basePath: string
}) => BreadcrumbList | null = ({ name, sitemap, basePath }) => {
    const breadcrumbs = sitemapToBreadcrumbs(sitemap, basePath)
    if (!breadcrumbs) return null
    return {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        '@type': 'BreadcrumbList',
        name,
        itemListElement: breadcrumbs
    }
}
