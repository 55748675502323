import { Organization, Person } from 'schema-dts'

import { EntityElastic } from '../../types/entity'
import { ProcessedAuthorMetadata } from '../../types/seo'
import { parseReferencableThing } from './referenceThing'

const parseAuthor = (item: EntityElastic): ProcessedAuthorMetadata | null => {
    const authorObject = item?.taxons?.authors?.[0]
    if (!authorObject) return null
    const { title, slug } = authorObject || {}
    return {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        '@type': 'Person',
        name: title,
        url: `/autori/${slug}`
    }
}

export const completeAuthorStructuredData: (
    organizationSchema: Organization,
    author?: ProcessedAuthorMetadata | null,
    basePath?: string
) => Organization | Person = (organizationSchema, author, basePath) => {
    if (!author) return parseReferencableThing(organizationSchema)
    if (!basePath || typeof author === 'string') return author
    return { ...author, url: `${basePath}${author.url}` }
}

export default parseAuthor
