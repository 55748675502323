/* eslint-disable prettier/prettier */
import { useRouter } from 'next/router'
import { NextSeoProps } from 'next-seo'
import { useMemo } from 'react'
import type { ItemList, Organization,  Thing,  WebPage,  WebSite } from 'schema-dts'

import {
    completeStructureData,
    completeWebMetadata,
    pagedNameTemplate
} from '../../../helpers/seo'
import { generateBreadcrumbStructuredData } from '../../../helpers/seo/breadcrumbs'
import getItemStructuredDataType from '../../../helpers/seo/getItemStructuredDataType'
import { parseReferencableThing } from '../../../helpers/seo/referenceThing'
import { completeWebPageStructuredData } from '../../../helpers/seo/webPage'
import { useBreakpoints, useSeoImages } from '../../../hooks'
import { EntityElastic } from '../../../types/entity'
import { OverrideItem, SeoOverride } from '../../../types/seo/SeoOverride'
import usePagedSeo from './usePagedSeo'

const PUBLIC_ROOT =
    process.env.NEXT_PUBLIC_APP_ROOT_RTL ||
    process.env.NEXT_PUBLIC_APP_ROOT_NET ||
    process.env.NEXT_PUBLIC_APP_ROOT_ZENA ||
    ''

interface UseSeoDataProps {
    item: EntityElastic
    images: any[]
    basePath?: string
    organization: Organization
    website: WebSite
    widgetLayout?: boolean
    page?: number
    listItems?: EntityElastic[]
    defaultImage?: {
        width: number
        height: number
        url: string
    }
    override?: SeoOverride
    minorItem?: boolean
    noStructuredData?: boolean
    structuredItemList?: ItemList | null
}

const useSeoData: (props: UseSeoDataProps) => {metadata?: NextSeoProps; mainStructuredData?: Thing | null} = ({
    item,
    images,
    basePath = PUBLIC_ROOT,
    organization,
    website,
    listItems,
    page,
    defaultImage,
    override,
    minorItem = false,
    noStructuredData = false
}: UseSeoDataProps) => {
    const { imageMeta: imagesMeta } = useSeoImages([item?.image, ...(images || [])].filter(Boolean), defaultImage)

    const [deviceType] = useBreakpoints(['xs', 'sm', 'md'], ['mobile', 'mobile', 'desktop'], null)

    const router = useRouter()
    const { asPath } = router

    const finalItemOverrides = useMemo(() => {
        if (!item || !override) {
            return undefined
        }
        const typeOverrides = (override?.[getItemStructuredDataType(item)] as Partial<OverrideItem>) || undefined
        const routeChecks = Object.keys(typeOverrides || {})

        if (!routeChecks.length) {
            return undefined
        }
        // eslint-disable-next-line security-node/non-literal-reg-expr
        const routeCheck = routeChecks.find(route => !!new RegExp(route, 'i').exec(asPath)?.length)
        if (!routeCheck?.[0]) {
            return undefined
        }
        return typeOverrides[routeCheck]
    }, [override, item, asPath])

    const pagedItem = usePagedSeo({ item, page, override: finalItemOverrides })
    const metadata = useMemo(() => {
        if (!item || (!item?.id && !item?.title) || minorItem) {
            return undefined
        }
        return completeWebMetadata({
            item,
            imagesMeta,
            device: deviceType,
            page,
            asPath,
            pagedItem,
            override: finalItemOverrides,
            basePath
        })
    }, [item, minorItem, imagesMeta, deviceType, page, asPath, pagedItem, finalItemOverrides, basePath])

    const mainStructuredData = useMemo(() => {
        if (!item || (!item?.id && !item?.title) || noStructuredData) {
            return null
        }
        const itemData = completeStructureData(item)({
            item,
            imagesMeta,
            metadata,
            organizationSchema: organization,
            page,
            listItems,
            asPath,
            pagedItem,
            override: finalItemOverrides,
            basePath,
            websiteSchema: website
        })

        if(minorItem) return itemData
        const webPageData = completeWebPageStructuredData({
            item,
            imagesMeta,
            metadata,
            organizationSchema: organization,
            page,
            listItems,
            asPath,
            pagedItem,
            override: finalItemOverrides,
            basePath,
            websiteSchema: website
        })

        const breadcrumb = generateBreadcrumbStructuredData({
            name: pagedItem?.name || pagedNameTemplate(metadata?.title, page),
            sitemap: item?.taxons?.sitemap?.[0]?.path || item?.taxons?.sitemap,
            basePath
        })
        if(itemData && itemData['@type'] === webPageData['@type']){
             if(breadcrumb) (itemData as WebPage).breadcrumb = breadcrumb
             return itemData as WebPage
             }
        webPageData.mainEntity = itemData || undefined

        if(breadcrumb) webPageData.breadcrumb = breadcrumb
        webPageData.publisher = parseReferencableThing(organization)
        return webPageData
    }, [
        item,
        imagesMeta,
        metadata,
        organization,
        page,
        listItems,
        asPath,
        pagedItem,
        finalItemOverrides,
        basePath,
        noStructuredData
    ])

    return {
        metadata,
        mainStructuredData
    }
}

export default useSeoData
