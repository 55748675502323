import { DocbookPage } from '../../types/docbook'

export const findFirstTitle = (page: DocbookPage) => {
    if (!page?.content) {
        return undefined
    }
    const heading = page.content.find(block => block.name === 'core/heading' && block.attributes?.content)
    if (!heading) {
        return undefined
    }
    return heading.attributes.content
}
export const findImageIds = (page: DocbookPage) =>
    page?.content
        ?.filter(block => block.name === 'hmn/image' && block.attributes?.id)
        ?.map(block => block.attributes?.id) || []

export const findParagraphContent = (page: DocbookPage) =>
    page?.content
        ?.filter(block => block.name === 'core/paragraph' && block.attributes?.content)
        ?.map(b => b.attributes.content) || []
