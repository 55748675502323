import { useMemo } from 'react'

import { useImageURL } from '../../../hooks'
import { EntityElastic } from '../../../types/entity'

interface UseEntityImageUrlOptions {
    ratio: string
    width: number
    height: number
    type: 'jpg' | 'webp'
}

const useEntityImageUrls = (entities: EntityElastic[], { ratio, width, height, type }: UseEntityImageUrlOptions) => {
    const entityImageIds: string[] = useMemo(() => entities?.map?.(article => article?.image?.id) || [], [entities])

    return useImageURL(entityImageIds, ratio, width, height, type) as string[]
}

export default useEntityImageUrls
