import { ImageObject } from 'schema-dts'

import { ImageMeta } from '../../types/seo'

export const completeListImageObject = (img: ImageMeta, parentPageUrl: string): ImageObject => ({
    // eslint-disable-next-line @typescript-eslint/naming-convention
    '@type': 'ImageObject',
    // eslint-disable-next-line @typescript-eslint/naming-convention
    '@id': img?.url,
    image: img?.url,
    url: parentPageUrl && img?.id && `${parentPageUrl}/${img?.id}`,
    name: img?.name || img?.alt
})
