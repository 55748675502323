import { EntityElastic } from '../../types/entity'
import { CompleteStructuredDataParsingFunction } from '../../types/seo/index'
import { completeArticleStructuredData } from './article'
import { completeGalleryStructuredData } from './gallery'
import getItemStructuredDataType from './getItemStructuredDataType'
import { completeMovieStructuredData } from './movie'
import { completeSitemapStructuredData } from './sitemap'
import { completeTVSeriesStructuredData } from './tvSeries'
import { completeVideoStructuredData } from './video'

const completeStructureData = (item: EntityElastic): CompleteStructuredDataParsingFunction => {
    // console.log('completeStructureData', item, getItemStructuredDataType(item))
    switch (getItemStructuredDataType(item)) {
        case 'article':
            return completeArticleStructuredData
        case 'gallery':
            return completeGalleryStructuredData
        case 'video':
            return completeVideoStructuredData
        case 'sitemap':
            return completeSitemapStructuredData
        case 'show':
            return completeTVSeriesStructuredData
        case 'movie':
            return completeMovieStructuredData
        default:
            return () => null
    }
}

export default completeStructureData
