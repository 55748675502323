import parseHtmlEntities from '../parseHtmlEntities'
import stripHtml from '../stripHtmlTags'
import truncateString from '../truncateString'

const parseDescription = (description, truncate = true, maxLength = 160, truncateEnd = '...') => {
    const parsed = parseHtmlEntities(stripHtml(description))
    return truncate ? truncateString(parsed, maxLength, truncateEnd) : parsed
}

export default parseDescription
