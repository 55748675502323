import type { SeoOverride } from '@hmn/rtl-web-core/types/seo/SeoOverride'

/* eslint-disable @typescript-eslint/naming-convention */
const metaOverrides: SeoOverride = {
    sitemap: {
        // '/danas.*': {
        //     title: 'Danas',
        //     description: (doesc: string) => `Danas je ${doesc}`
        // },
    }
    // general: {
    //     '/najnovije.*': {
    //         // title: 'Najnovije',
    //     }
    // }
}

export default metaOverrides
