import { useMemo } from 'react'

import { findFirstTitle, findImageIds, findParagraphContent } from '../../../helpers/docbook'
import { applyOverrideableSeoProperties } from '../../../helpers/seo/applyOverrideValue'
import stripHtml from '../../../helpers/stripHtmlTags'
import { useBlockList } from '../../../hooks'
import useSeoImages from '../../../hooks/useSeoImages'
import { EntityElastic } from '../../../types/entity'
import { PagedItemSeo } from '../../../types/seo'
import { OverrideItem } from '../../../types/seo/SeoOverride'

interface UsePagedSeoProps {
    item: EntityElastic
    page?: number
    override?: Partial<OverrideItem>
}

const usePagedSeo = ({ item, page, override }: UsePagedSeoProps): null | PagedItemSeo => {
    const docbook = item?.body
    const articleType = item?.taxons?.['article-type']?.[0]?.slug
    const isPaged = articleType?.match('article-type-prev-next')
    const blockList = useBlockList({ docbook, isPaged })
    const usePagedData = item?.type === 'article' && page && page >= 2
    const {
        name,
        imageIds = [],
        body
    } = useMemo(() => {
        if (!usePagedData) return {}
        const currentDocbookPage = blockList[Math.min(page - 1, blockList.length - 1)]

        const { title } = applyOverrideableSeoProperties(
            { title: stripHtml(findFirstTitle(currentDocbookPage)?.toString()) },
            override
        )

        return {
            name: title,
            imageIds: findImageIds(currentDocbookPage),
            body: findParagraphContent(currentDocbookPage).join(' ').trim()
        }
    }, [blockList, page, item, usePagedData])
    const { imageMeta: images } = useSeoImages(imageIds, undefined)
    if (!usePagedData) return null
    return { name, images, body, page }
}

export default usePagedSeo
