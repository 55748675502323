// eslint-disable-next-line @typescript-eslint/naming-convention
import Head from 'next/head'
import { useMemo } from 'react'
import { useUIDSeed } from 'react-uid'
import { Thing, WithContext } from 'schema-dts'

const firstKeys = [
    '@context',
    '@id',
    '@type',
    'headline',
    'name',
    'dateCreated',
    'datePublished',
    'dateModified',
    'description',
    'author'
]

const sortObjectKeys = <T extends string>(object: Record<T, any>, keys: T[]): Record<T, any> => {
    return Object.keys(object)
        .sort((a, b) => {
            const aKeyIndex = keys.indexOf(a as T)
            const bKeyIndex = keys.indexOf(b as T)
            if (aKeyIndex === -1 && bKeyIndex !== -1) return 1
            if (bKeyIndex === -1 && aKeyIndex !== -1) return -1
            return aKeyIndex - bKeyIndex
        })
        .reduce(
            (acc, curr) => {
                acc[curr as T] = object[curr as T]
                return acc
            },
            {} as Record<T, any>
        )
}

const removeUndefinedObjectKeys = (object, ignoredKeys: string[] = []): any =>
    Object.keys(object).reduce((acc, curr) => {
        let value = object[curr]
        if (curr === 'name' && acc.headline && acc.headline === value) {
            return acc
        }
        if (value === Object(value) && !Array.isArray(value)) {
            value = removeUndefinedObjectKeys(value)
            // if object has no other keys without ignored ones, remove the object
            if (ignoredKeys && Object.keys(value).filter(key => !ignoredKeys.includes(key)).length === 0) {
                return acc
            }
        }
        if (!value && value !== 0) {
            // any null-ish value except 0
            return acc
        }
        acc[curr] = value
        return acc
    }, {} as any)

const checkContext = (schemaData: Thing | WithContext<Thing>): WithContext<Thing> => {
    if (schemaData['@context'] !== 'https://schema.org') schemaData['@context'] = 'https://schema.org'

    return schemaData as WithContext<Thing>
}

const StructuredData = ({ data, useGraph = false }: { data: any[] | any; useGraph?: boolean }) => {
    const uid = useUIDSeed()

    const finalSchemaObject = useMemo(() => {
        if (!data) return null

        const arrayData = (Array.isArray(data) ? data : [data]).filter(Boolean)

        if (arrayData.length === 0) return null

        const itemParser = useGraph
            ? ({ ignoreKeys = [], ...dat }: any) => removeUndefinedObjectKeys(dat, ignoreKeys)
            : ({ ignoreKeys = [], ...dat }: any) => checkContext(removeUndefinedObjectKeys(dat, ignoreKeys))

        const parsedData = arrayData.map(itemParser).map(schemaObject => sortObjectKeys(schemaObject, firstKeys))

        return (
            (useGraph && {
                // eslint-disable-next-line @typescript-eslint/naming-convention
                '@schema': 'https://schema.org',
                // eslint-disable-next-line @typescript-eslint/naming-convention
                '@graph': parsedData
            }) ||
            parsedData
        )
    }, [data, useGraph])

    if (!finalSchemaObject) {
        return null
    }

    return (
        <>
            <Head>
                <script
                    key={uid('json-ld-schema')}
                    type="application/ld+json"
                    // eslint-disable-next-line react/no-danger
                    // eslint-disable-next-line @typescript-eslint/naming-convention
                    dangerouslySetInnerHTML={{ __html: JSON.stringify(finalSchemaObject) }}
                />
            </Head>
        </>
    )
}

export default StructuredData
