import dayjs from 'dayjs'
import { Movie } from 'schema-dts'

import { EntityElastic } from '../../types/entity'
import { CompleteStructuredDataParserProps, ProcessedMovieStructuredData } from '../../types/seo'
import truncate from '../truncateString'
import { applyOverrideableSeoProperties } from './applyOverrideValue'
import { ParsedBasicProperties } from './basicSchemaProperties'
import parseSeoPerson from './person'
import { parseReferencableThing } from './referenceThing'
import parseSeoUrl from './url'

export const completeMovieStructuredData: (props: CompleteStructuredDataParserProps) => Movie | null = ({
    item,
    imagesMeta,
    asPath,
    override,
    basePath,
    organizationSchema,
    websiteSchema
}) => {
    if (!item?.structuredData?.parsed) return null
    const { structuredData } = item
    const {
        parsed: { name: sdName, description: sdDescription, ...parsedStructuredData },
        data: intermediateData
    } = structuredData as ProcessedMovieStructuredData
    const { summary } = intermediateData

    const { title: name, description } = applyOverrideableSeoProperties(
        { title: sdName?.toString(), description: sdDescription?.toString() },
        override
    )
    const image = imagesMeta?.[0]?.url
    const url = parseSeoUrl(item, asPath, basePath)
    // @NOTE: rest of the properties are appended once frontend values are known
    return {
        ...parsedStructuredData,
        name,
        description,
        image,
        url,
        publisher: parseReferencableThing(organizationSchema),
        ...(!summary && {
            thumbnailUrl: image,
            isPartOf: parseReferencableThing(websiteSchema)
        })
    }
}

const parseMovie = (
    item: EntityElastic,
    basicProperties: ParsedBasicProperties,
    summary = false,
    bodyMaxWords = 100
): ProcessedMovieStructuredData => {
    const { title, author, publishDate, updateDate } = basicProperties
    const { isNsfw } = item?.extended_attributes || {}
    const { short_description: shortDescription, title_original: alternateName, year } = item || {}
    const genre = item?.taxons?.['show-genre']?.[0]?.title
    const data = { summary, bodyMaxWords, author }
    const actor = parseSeoPerson(item?.['voice-cast'])
    const director = parseSeoPerson(item?.director)
    // @TODO: finish once you find proper test case
    const parsed: Movie = {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        '@type': 'Movie',
        name: title,
        alternateName,
        headline: truncate(title, 110, '...', false),
        contentRating: isNsfw ? 'Mature' : 'All audiences',
        director,
        genre,
        actor,
        description: shortDescription,
        dateCreated: publishDate || updateDate,
        datePublished: (year && dayjs(year).toISOString()) || undefined,
        // producer
        //     @type: Person
        //     name: producer (treba protrčati kroz sve i odvojiti ih zarezom)

        ...(!summary && {})
    }

    return {
        data,
        parsed
    }
}

export default parseMovie
