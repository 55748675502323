const escapeRegExp = (text: string) => text.replace(/[.*+?^${}()|[\]\\]/g, '\\$&') // $& means the whole matched string

/**
 * Shorten string to specified length (if longer) including configurable ending (default: "...")
 * @param {string} text - text to be shortened if longer than the input length
 * @param {number} length - default: 100
 * @param {string} end - default: "..."
 * @param {bool} splitWords - default: false - if false truncate will keep words whole (might shorten string to a bit less than length)
 * @return {string} original string (if shorter than length) or shortened string with appended end
 */
const truncate = (textInput?: string, lengthInput = 100, delimiter = '...', splitWords = false): string => {
    if (!textInput) return ''
    // eslint-disable-next-line security-node/non-literal-reg-expr
    const text = delimiter ? textInput?.replace(new RegExp(`${escapeRegExp(delimiter)}$`, 'gi'), '') : textInput
    if (text.length < lengthInput) {
        return text
    }
    const length = lengthInput - delimiter.length
    const trimmedString = text.substring(0, length).trim()
    if (splitWords) {
        return trimmedString + delimiter
    }
    const trimmedResult = trimmedString.substring(0, Math.min(trimmedString.length, trimmedString.lastIndexOf(' ')))
    return trimmedResult + delimiter
}
export default truncate
