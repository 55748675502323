import type { Thing } from 'schema-dts'

export const isThingReferencable = (thing: Thing): boolean => !!thing?.['@id']

export const parseReferencableThing = <Type extends Thing>(thing: Type): Type =>
    ((isThingReferencable(thing) && {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        '@id': thing['@id'] // @NOTE: this assumes that the thing is mentioned in the same page under the same @id value
    }) ||
        thing) as Type
