import deepmerge from 'deepmerge'
import { NextSeoProps } from 'next-seo'

import { pagedNameTemplate } from '../../helpers/seo'
import { EntityElastic } from '../../types/entity'
import { CompleteMetaData, ImageMeta, PagedItemSeo, ProcessedEntityMetadata } from '../../types/seo'
import { OverrideItem } from '../../types/seo/SeoOverride'
import { getImageMimeType } from '../mimeType'
import truncate from '../truncateString'
import { applyOverrideableSeoProperties } from './applyOverrideValue'
import parseSeoAuthor from './author'
import parseSeoDate from './date'
import parseSeoDescription from './description'
import parseSeoTags from './tags'
import parseSeoTitle from './title'
import parseSeoUrl from './url'

// @NOTE: process paged meta and seo on SSR/SSG or client on SPA
// const pagedNameTemplate = (name: string, page: number) => `${name}${page ? ` | Stranica ${page}` : ''}`

// @TODO: expand for other entity types
const getMetaType = (entityType: string) => {
    switch (entityType) {
        case 'sitemap':
            return 'website'
        case 'video':
            return 'video.other'
        default:
            return entityType
    }
}

interface CompleteWebMetadataProps {
    item: EntityElastic
    imagesMeta: ImageMeta[] | null
    device?: string
    page?: number
    asPath?: string
    pagedItem?: PagedItemSeo | null
    override?: Partial<OverrideItem>
    basePath?: string
}

export type CompleteMetaDataParsingFunction = (props: CompleteWebMetadataProps) => CompleteMetaData

export const completeWebMetadata: CompleteMetaDataParsingFunction = ({
    item,
    imagesMeta,
    device = 'desktop',
    page,
    asPath,
    pagedItem,
    override,
    basePath
}) => {
    // @TODO: parse device title
    const { metadata: { parsed: apiParsedMeta, data } = makeWebMetadata(item) || {} } = item

    const url = parseSeoUrl(item, asPath, basePath)
    const { googleTitle, socialTitle: socialTitleData, mobileTitle, mainTitle, publishDate } = data || {}
    const isMobile = device === 'mobile'
    const parsedMainTitle = googleTitle || (isMobile && mobileTitle) || mainTitle
    const parsedSocialTitle = socialTitleData || (isMobile && mobileTitle) || mainTitle
    const images = [pagedItem?.images?.[0] || imagesMeta?.[0]].filter(Boolean).map((image: any) => {
        if (!image.alt) {
            image.alt = image.name || parsedMainTitle
        }
        if (!image.type && image.url) {
            image.type = getImageMimeType(image.url) || undefined
        }
        return image
    })

    const initialDescription = pagedItem?.body ? parseSeoDescription(pagedItem?.body) : apiParsedMeta?.description
    const { title, description, socialTitle } = applyOverrideableSeoProperties(
        {
            title: pagedItem?.name || pagedNameTemplate(parsedMainTitle, page),
            description: initialDescription,
            socialTitle: pagedItem?.name || pagedNameTemplate(parsedSocialTitle, page)
        },
        override
    )

    const dateOnly = publishDate?.split('T')[0]

    const missingMetaProperties: Partial<NextSeoProps> = {
        title,
        canonical: url,
        description,
        openGraph: {
            description,
            title: socialTitle,
            url,
            images
        },
        additionalMetaTags: dateOnly
            ? [
                  {
                      property: 'publish-date',
                      content: dateOnly
                  }
              ]
            : []
    }

    return deepmerge(apiParsedMeta || {}, missingMetaProperties || {})
}

const makeWebMetadata = (entity: any): ProcessedEntityMetadata | null => {
    if (!entity) return null
    const {
        google_title: googleTitle,
        mobile_title: mobileTitle,
        social_title: socialTitleInput
    } = entity?.extended_attributes || {}

    const mainTitle = parseSeoTitle({ item: entity })
    const socialTitle = (socialTitleInput && truncate(socialTitleInput)) || mainTitle
    const description = parseSeoDescription(entity?.description || entity?.lead)
    const tags = entity?.tags ? parseSeoTags(entity?.tags) : []
    const publishDate = parseSeoDate(entity?.published_at || entity?.updated_at)
    const updateDate = parseSeoDate(entity?.updated_at)
    const author = parseSeoAuthor(entity)
    const authors = typeof author === 'string' ? [author] : author?.name ? [author?.name.toString()] : undefined

    return {
        // @NOTE: this data is decided on client or used in other parsing steps
        data: { googleTitle, socialTitle, mobileTitle, mainTitle, publishDate, updateDate, author },
        // @NOTE: parsed data follows next-seo interface
        parsed: {
            description,
            openGraph: {
                description,
                type: getMetaType(entity.type),
                article: {
                    publishedTime: publishDate,
                    modifiedTime: updateDate,
                    authors,
                    tags
                }
            },
            robotsProps: {
                maxImagePreview: 'large',
                maxVideoPreview: 10
            }
        }
    }
}

export default makeWebMetadata
