import { ListItem, Organization, WebSite } from 'schema-dts'

import { completeSeoFromData } from '../../components/Seo/helpers'
import { EntityElastic, EntityType } from '../../types/entity'
import uniqueByKey from '../uniqueByKey'
import processItemUrl from './url'

interface CompleteItemListStructuredDataProps {
    items: EntityElastic[]
    imageUrls: string[]
    description?: string
    name?: string
    ascendingOrder?: boolean
    unordered?: boolean
    maxItems?: number
    organizationSchema: Organization
    websiteSchema: WebSite
    summary?: boolean
}

const completeSingleListItem = (
    item: EntityElastic,
    imageUrl: string,
    position: number,
    organization: Organization,
    website: WebSite,
    summary = false
): ListItem | null => {
    if (summary) {
        return {
            // eslint-disable-next-line @typescript-eslint/naming-convention
            '@type': 'ListItem',
            position,
            url: processItemUrl(item),
            image: imageUrl,
            name: item.title
        }
    }
    // console.log('completeSingleListItem', item, imageUrl, position, organization)
    const { structuredData: itemStructuredData } = completeSeoFromData({
        item,
        organization,
        imagesMeta: [{ url: imageUrl }],
        website
    })
    if (!itemStructuredData) {
        return null
    }
    if (typeof itemStructuredData !== 'string' && typeof itemStructuredData.url !== 'undefined') {
        itemStructuredData.url = item.url
    }
    return {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        '@type': 'ListItem',
        position,
        item: itemStructuredData
    }
}

const typeToNameMapPlural = Object.freeze({
    article: 'Članci',
    video: 'Videozapisi',
    gallery: 'Galerije',
    show: 'Emisije',
    movie: 'Filmovi',
    general: 'Sadržaji',
    sitemap: 'Vezane kategorije'
})

const itemTypeToGroupName = (type?: EntityType) => {
    if (!type) {
        return typeToNameMapPlural.general
    }
    return typeToNameMapPlural[type] || typeToNameMapPlural.general
}

const completeItemListStructuredData = ({
    items: loadedItems,
    imageUrls,
    name,
    description,
    maxItems = 50,
    unordered,
    ascendingOrder = true,
    organizationSchema,
    websiteSchema,
    summary = false
}: CompleteItemListStructuredDataProps) => {
    if (!loadedItems || loadedItems.length === 0) {
        return null
    }
    const filteredItems = uniqueByKey(
        loadedItems
            ?.filter(Boolean)
            .map((item, index) => ({ ...item, url: processItemUrl(item), imageUrlInternal: imageUrls[index] })) || [],
        'url'
    ).slice(0, maxItems)

    if (filteredItems?.length <= 0) {
        return null
    }
    const sortedByType: { [key in EntityType]?: EntityElastic[] } = summary
        ? { general: filteredItems }
        : filteredItems.reduce(
              (acc, item) => {
                  const type = item?.type || 'general'
                  if (!acc[type]) {
                      acc[type] = []
                  }
                  acc[type].push(item)
                  return acc
              },
              {} as { [key in EntityType]?: EntityElastic[] }
          )

    return Object.entries(sortedByType).map(([type, items]) => ({
        // eslint-disable-next-line @typescript-eslint/naming-convention
        '@type': 'ItemList',
        name: name + ' | ' + itemTypeToGroupName(type as EntityType),
        description,
        itemListOrder: `https://schema.org/ItemList${
            (unordered && 'Unordered') || (ascendingOrder && 'OrderAscending') || 'OrderDecending'
        }`,
        numberOfItems: items?.length,
        itemListElement: items
            ?.map((item, index) =>
                completeSingleListItem(
                    item,
                    item.imageUrlInternal,
                    index + 1,
                    organizationSchema,
                    websiteSchema,
                    summary
                )
            )
            ?.filter(Boolean)
    }))
}

export default completeItemListStructuredData
