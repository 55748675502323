import dayjs from 'dayjs'
import { TVSeries } from 'schema-dts'

import { EntityElastic } from '../../types/entity'
import { CompleteStructuredDataParserProps, ProcessedTVSeriesStructuredData } from '../../types/seo'
import truncate from '../truncateString'
// import { applyOverrideableSeoProperties } from './applyOverrideValue'
import { ParsedBasicProperties } from './basicSchemaProperties'
import parseSeoPerson from './person'
import { parseReferencableThing } from './referenceThing'

export const completeTVSeriesStructuredData: (props: CompleteStructuredDataParserProps) => TVSeries | null = ({
    item,
    imagesMeta,
    organizationSchema,
    websiteSchema
}) => {
    if (!item?.structuredData?.parsed) return null
    const { structuredData } = item
    const { parsed: parsedStructuredData, data: intermediateData } = structuredData as ProcessedTVSeriesStructuredData
    const { summary } = intermediateData
    const name = parsedStructuredData.name
    const image = imagesMeta?.[0]?.url
    // @NOTE: rest of the properties are appended once frontend values are known
    return {
        ...parsedStructuredData,
        name,
        image,
        publisher: parseReferencableThing(organizationSchema),
        ...(!summary && {
            thumbnailUrl: image,
            isPartOf: parseReferencableThing(websiteSchema)
        })
    }
}

const parseTVSeries = (
    item: EntityElastic,
    basicProperties: ParsedBasicProperties,
    summary = false,
    bodyMaxWords = 100
): ProcessedTVSeriesStructuredData => {
    const { title, author, publishDate, updateDate } = basicProperties
    const { isNsfw } = item?.extended_attributes || {}
    const { short_description: shortDescription, title_original: alternateName, year } = item || {}
    const genre = item?.taxons?.['show-genre']?.[0]?.title
    const data = { summary, bodyMaxWords, author }
    const actor = parseSeoPerson(item?.['voice-cast'])
    const director = parseSeoPerson(item?.director)
    // @TODO: finish once you find proper test case
    const parsed: TVSeries = {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        '@type': 'TVSeries',
        name: title,
        alternateName,
        headline: truncate(title, 110, '...'),
        contentRating: isNsfw ? 'Mature' : 'All audiences',
        director,
        genre,
        actor,
        description: shortDescription,
        dateCreated: publishDate || updateDate,
        datePublished: (year && dayjs(year).toISOString()) || undefined,
        // producer
        //     @type: Person
        //     name: producer (treba protrčati kroz sve i odvojiti ih zarezom)

        ...(!summary && {})
    }

    return {
        data,
        parsed
    }
}

export default parseTVSeries
