import deepmerge from 'deepmerge'
import { NextSeo } from 'next-seo'
import { useMemo } from 'react'
import { Organization, WebPage, WebSite } from 'schema-dts'

import { isThingReferencable } from '../../helpers/seo/referenceThing'
import { EntityElastic } from '../../types/entity'
import { SeoOverride } from '../../types/seo/SeoOverride'
import { StructuredData } from '../StructuredData'
import { useItemListStructuredData, useSeoData } from './hooks'

export interface SeoProps {
    item: any
    images: any[] // @TODO: specify entity types
    hasAmp?: boolean
    basePath?: string
    organization: Organization
    website: WebSite
    listItems: EntityElastic[]
    widgetListWithData?: any // @TODO: specify entity types
    defaultImage?: {
        width: number
        height: number
        url: string
    }
    override?: SeoOverride
    mainStructuredDataOverride?: WebPage
    minorItem?: boolean
    minorList?: boolean
    page: number
    noStructuredData?: boolean
}

const LIST_IS_SUMMARY = true

export const Seo = ({
    item,
    images,
    basePath,
    organization,
    website,
    listItems,
    widgetListWithData,
    defaultImage,
    override,
    mainStructuredDataOverride,
    page,
    minorItem = false,
    minorList = false,
    noStructuredData = false
}: SeoProps) => {
    const structuredItemList = useItemListStructuredData({
        mainItem: item,
        listItems,
        widgetListWithData,
        organizationSchema: organization,
        websiteSchema: website,
        page,
        enabled: (!minorItem || minorList) && !noStructuredData,
        summary: LIST_IS_SUMMARY
    })
    const { metadata, mainStructuredData } = useSeoData({
        item,
        images,
        basePath,
        organization,
        website,
        listItems,
        page,
        defaultImage,
        override,
        minorItem,
        noStructuredData
    })

    const mainItemStructuredData = useMemo(() => {
        if (!mainStructuredData) {
            if (mainStructuredDataOverride) return mainStructuredDataOverride
            return null
        }
        if (!mainStructuredDataOverride) {
            return mainStructuredData
        }
        return deepmerge(mainStructuredData as any, mainStructuredDataOverride as any) as WebPage
    }, [mainStructuredData, mainStructuredDataOverride])

    const structuredDataArray = useMemo(() => {
        const hasList = (!minorItem || minorList) && !!structuredItemList
        return [
            ...((!minorItem &&
                [isThingReferencable(organization) && organization, isThingReferencable(website) && website].filter(
                    Boolean
                )) ||
                []),
            mainItemStructuredData,
            ...((hasList && structuredItemList) || [])
        ]
    }, [mainItemStructuredData, structuredItemList, minorItem, minorList])

    return (
        <>
            {(!minorItem && !minorList && <>{metadata && <NextSeo {...metadata} />}</>) || null}
            {(!noStructuredData && structuredDataArray.length && <StructuredData data={structuredDataArray} />) || null}
        </>
    )
}
export default Seo
