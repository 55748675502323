import { getWidgetComponentsFromList } from './layout-builder/getWidgetComponentsFromList'

const CategoryType = Object.freeze({
    LISTING: 'listing-page',
    LAYOUT: 'widget-based-page'
})

export function isCategoryLayout(category, najnovije = false) {
    if (najnovije) {
        return false
    }

    return category.pageType?.slug === CategoryType.LAYOUT
}

export function isCategoryListing(category, najnovije = false) {
    return !isCategoryLayout(category, najnovije)
}

export function getWidgetListWithData(categoryLayout, WidgetComponents, ComponentType) {
    const { widgetList, entityList } = categoryLayout

    return {
        desktop: getWidgetComponentsFromList({
            // NOTE: Maybe this is good now (8.7.2023, @mario.basic). Nope.
            // TODO: locate errant mutation
            // NOTE: This must be done since getWidgetComponentsFromList mutates widgetList somewhere
            widgetList: JSON.parse(JSON.stringify(widgetList)),
            // widgetList,
            entityList,
            device: 'desktop',
            WidgetComponents,
            ComponentType
        }),
        mobile: getWidgetComponentsFromList({
            widgetList,
            entityList,
            device: 'mobile',
            WidgetComponents,
            ComponentType
        })
    }
}

export const mapWidgetsPropRecursive = (widgets, propName) =>
    widgets?.reduce((acc, curr) => {
        if (curr?.props?.[propName]) {
            acc.push(curr.props[propName])
        }
        if (curr?.props?.widgets) {
            acc.push(...mapWidgetsPropRecursive(curr.props.widgets || [], propName))
        }
        return acc
    }, []) || []

export function getCategoryLayoutWidgetArticles(widgetListWithData, isDesktop) {
    const currentDeviceWidgetList = widgetListWithData?.[isDesktop ? 'desktop' : 'mobile']

    if (!currentDeviceWidgetList) {
        return []
    }

    const widgetArticles = mapWidgetsPropRecursive(currentDeviceWidgetList, 'items')?.reduce(
        (acc, curr) => [...acc, ...curr.map(c => c?.item)],
        []
    )

    return widgetArticles
}
