import getConfig from 'next/config'

import { Seo, SeoProps } from '@hmn/rtl-web-core/components/Seo/Seo.component'

import seoOverrides from '../config/meta/overrides'
import { organization, website } from '../config/seo'

const { publicRuntimeConfig = {} } = getConfig()
const { assetPrefix } = publicRuntimeConfig

const PUBLIC_ROOT = process.env.NEXT_PUBLIC_APP_ROOT_NET

const defaultShareImage = {
    url: `${assetPrefix || PUBLIC_ROOT || ''}/images/share/default.png`,
    width: 1200,
    height: 630
}

// @NOTE: This component prepares genral Seo component with rtl.hr specifics
const ZenaSeo = ({
    item,
    images,
    listItems,
    organization: inputOrganization,
    website: inputWebsite,
    page,
    ...rest
}: SeoProps) => {
    return (
        <Seo
            item={item}
            images={images}
            listItems={listItems}
            organization={inputOrganization || organization}
            website={inputWebsite || website}
            defaultImage={defaultShareImage}
            override={seoOverrides}
            basePath={PUBLIC_ROOT}
            page={page}
            {...rest}
        />
    )
}

export default ZenaSeo
