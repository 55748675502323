import { EntityElastic } from '../../types/entity'
import stripHtml from '../stripHtmlTags'
import truncate from '../truncateString'

const getTitleValue = (item: EntityElastic) => item?.extended_attributes?.seo_title || item?.title || item?.name

const processTitle = ({ item, page, pagedItem }: { item: any; page?: number; pagedItem?: any }) => {
    const articlePath = item?.taxons?.sitemap?.[0]?.path

    const title = getTitleValue(page ? pagedItem : item)
    const isZodiac = articlePath?.some(p => p.slug.match(/horoskop/gim))
    return truncate(
        `${isZodiac ? `${articlePath[articlePath.length - 1].title} - ` : ''}${stripHtml(title)}`,
        110,
        '...'
    )
}

export default processTitle
