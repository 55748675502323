import { OverrideItem, OverrideValue } from '../../types/seo/SeoOverride'
import seoDefaultKeyMap from './defaultKeyMap'

export const applySeoPropertyOverride = <T>(initialValue: T, override?: OverrideValue<T>) => {
    if (typeof override !== 'undefined') {
        if (typeof override === 'function') {
            return override(initialValue)
        }
        return override
    }
    return initialValue
}

export const applyOverrideableSeoProperties = <T extends Partial<OverrideItem>>(
    overrideableValues: T,
    override?: Partial<OverrideItem>
): T => {
    if (!override || Object.keys(override).length === 0) return overrideableValues
    const result = {} as T
    Object.entries(overrideableValues).forEach(([key, value]) => {
        const ov = override[key] || override[seoDefaultKeyMap[key]]
        if (!ov) result[key] = value
        result[key] = applySeoPropertyOverride(value, ov)
    })
    return result
}
