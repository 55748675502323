import { CollectionPage } from 'schema-dts'

import { EntityElastic } from '../../types/entity'
import { CompleteStructuredDataParserProps, ProcessedSitemapStructuredData } from '../../types/seo'
import { applyOverrideableSeoProperties } from './applyOverrideValue'
import parseBasicSchemaProperties, { ParsedBasicProperties } from './basicSchemaProperties'
import { parseReferencableThing } from './referenceThing'
import parseSeoUrl from './url'

export const completeSitemapStructuredData = ({
    item,
    imagesMeta,
    organizationSchema,
    asPath,
    override,
    basePath,
    websiteSchema
}: CompleteStructuredDataParserProps): CollectionPage => {
    const { structuredData = parseSitemap(item, parseBasicSchemaProperties(item)), name: itemName } = item
    // const {
    //     parsed: parsedStructuredData // data: intermediateData
    // } = structuredData as ProcessedSitemapStructuredData
    const {
        parsed: { name: sdName, description: sdDescription, headline: sdHeadline, ...parsedStructuredData } // data: intermediateData
    } = structuredData as ProcessedSitemapStructuredData
    const { title: name, description } = applyOverrideableSeoProperties(
        { title: itemName || sdName?.toString(), description: sdDescription?.toString() },
        override
    )
    // console.log('sitemap', name, description, override)
    // const { bodyMaxWords } = intermediateData
    const url = parseSeoUrl(item, asPath, basePath)
    // @NOTE: rest of the properties are appended once frontend values are known
    return {
        ...parsedStructuredData,
        image: imagesMeta?.[0]?.url,
        thumbnailUrl: imagesMeta?.[0]?.url,
        publisher: parseReferencableThing(organizationSchema),
        isPartOf: parseReferencableThing(websiteSchema),
        name,
        description,
        headline: name || sdHeadline,
        // primaryImageOfPage: imagesMeta?.[0]?.url,
        mainEntityOfPage: url
    }
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const parseSitemap = (
    item: EntityElastic,
    basicProperties: ParsedBasicProperties,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    summary = false,
    bodyMaxWords = 100
): ProcessedSitemapStructuredData => {
    const { title, description, publishDate, updateDate } = basicProperties
    const data = { bodyMaxWords }
    const parsed: CollectionPage = {
        // image: imageUrl,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        '@type': 'CollectionPage',
        name: title,
        headline: title,
        description,
        inLanguage: 'Croatian',
        // thumbnailUrl: imageUrl,
        dateCreated: publishDate,
        datePublished: publishDate,
        dateModified: updateDate,
        // keywords,
        about: description
    }

    return {
        data,
        parsed
    }
}

export default parseSitemap
