import { ImageGallery } from 'schema-dts'

import { ImageMeta } from '../../../types/seo'
import { completeListImageObject } from '../image'

const completeImageGallery = (images: ImageMeta[], parentUrl: string): ImageGallery => ({
    // eslint-disable-next-line @typescript-eslint/naming-convention
    '@type': 'ImageGallery',
    image: images?.map(img => completeListImageObject(img, parentUrl))
})

export default completeImageGallery
