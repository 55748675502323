import { useMemo } from 'react'
import { Organization, WebSite } from 'schema-dts'

import { getCategoryLayoutWidgetArticles } from '../../../helpers/category'
import { completeItemListStructuredData } from '../../../helpers/seo'
import useBreakpoints from '../../../hooks/useBreakpoints'
import { EntityElastic } from '../../../types/entity'
import { getBasicSeoData } from '../helpers'
import useEntityImageUrls from './useEntityImageUrls'

interface UseItemListStructureData {
    listItems: EntityElastic[]
    mainItem: EntityElastic
    organizationSchema: Organization
    websiteSchema: WebSite
    widgetListWithData?: any
    page?: number
    enabled?: boolean
    summary?: boolean
}
const useItemListStructuredData = ({
    mainItem,
    listItems: listItemsInput,
    widgetListWithData,
    organizationSchema,
    websiteSchema,
    page,
    enabled = true,
    summary = false
}: UseItemListStructureData) => {
    const [isDesktop] = useBreakpoints('md')
    const listItems = useMemo(
        () =>
            widgetListWithData ? getCategoryLayoutWidgetArticles(widgetListWithData, isDesktop) : listItemsInput || [],
        [listItemsInput, isDesktop]
    )
    const imageUrls: string[] = useEntityImageUrls(listItems, {
        ratio: 'custom-3-2',
        width: 1200,
        height: 800,
        type: 'jpg'
    })
    const { name, description } = useMemo(
        () => (enabled && getBasicSeoData(mainItem, page)) || ({} as any),
        [mainItem, page, enabled]
    )

    return useMemo(
        () =>
            (enabled &&
                completeItemListStructuredData({
                    items: listItems,
                    imageUrls,
                    name,
                    description,
                    organizationSchema,
                    websiteSchema,
                    summary
                })) ||
            null,
        [listItems, imageUrls, name, description, organizationSchema, enabled, summary]
    )
}
export default useItemListStructuredData
