/* eslint-disable @typescript-eslint/naming-convention */
import { ImageObject, Organization } from 'schema-dts'

const PUBLIC_NAME = process.env.NEXT_PUBLIC_APP_NAME_ZENA
const PUBLIC_URL = process.env.NEXT_PUBLIC_APP_ROOT_ZENA
export const logo: ImageObject = {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    '@type': 'ImageObject',
    url: PUBLIC_URL,
    contentUrl: `${PUBLIC_URL}/images/amp/zenahr-publisher-600x60.png`,
    width: '600px',
    height: '60px'
}

export const organization: Organization = {
    '@type': 'Organization',
    '@id': '#zena-hr-org',
    name: PUBLIC_NAME,
    url: PUBLIC_URL,
    // @TODO: input correct values for rtl
    sameAs: [
        'https://www.facebook.com/www.zena.hr/',
        'https://www.instagram.com/zenahr/',
        'https://twitter.com/zenahr'
    ],
    logo
}
