import { EntityElastic } from '../../types/entity'
import parseSeoAuthor from './author'
import parseDate from './date'
import parseDescription from './description'
import parseTags from './tags'
import processTitle from './title'

export const pagedNameTemplate = (name, page?: number, separator = '|') => {
    if (!page || page <= 1) return name
    const parsedName = name.replace(new RegExp('(/|\\|) Stranica \\d*', 'gim'), '').trim()
    return `${parsedName}${` ${separator} Stranica ${page}`}`
}

const parseBasicSchemaProperties = (item: EntityElastic) => {
    const title = processTitle({ item })
    const description = parseDescription(item?.description || item?.lead || item?.content)
    const sections = item?.taxons?.sitemap?.[0]?.path?.map(p => p?.title).filter(Boolean)
    const publishDate = parseDate(item?.published_at || item?.updated_at)
    const updateDate = parseDate(item?.updated_at)
    const tags = parseTags(item?.tags || [])
    const author = parseSeoAuthor(item)
    return { title, description, sections, publishDate, updateDate, tags, author }
}

export type ParsedBasicProperties = ReturnType<typeof parseBasicSchemaProperties>

export default parseBasicSchemaProperties
