/**
 * Filters array by unique key
 * if object is undefined, a primitive or doesn't have the specified key, it is ignored
 * prioritises first occurence of an object
 * @param {Array.<T>} arr - array of objects
 * @param {String} key - object key that is used for filtering
 * @returns {Array.<T>} filtered - unique objects array
 */
const uniqueByKey = (arr, key) => {
    if (!arr || !Array.isArray(arr) || !key) {
        return []
    }
    const mappedArr = arr.map(e => e?.[key] || null) || []
    return arr.filter((curr, index) => index === mappedArr.indexOf(curr[key])) || []
}
export default uniqueByKey
