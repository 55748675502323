import { pagedNameTemplate } from '../../../helpers/seo'
import { EntityElastic } from '../../../types/entity'

const getBasicSeoData = (entity?: EntityElastic, page?: number): { name: string; description: string } | null => {
    if (!entity) return null
    const {
        metadata: { parsed: parsedMeta, data: dataMeta } = {},
        structuredData: { parsed: parsedSd } = {},
        name,
        title,
        description
    } = entity

    const mainName = parsedSd?.name?.toString() || dataMeta?.mainTitle || name || title

    return {
        name: pagedNameTemplate(mainName, page),
        description: parsedSd?.description?.toString() || parsedMeta?.description || description
    }
}

export default getBasicSeoData
