import { WebPage } from 'schema-dts'

import { CompleteStructuredDataParserProps, ProcessedWebPageStructuredData } from '../../types/seo'
import { applyOverrideableSeoProperties } from './applyOverrideValue'
import parseBasicSchemaProperties from './basicSchemaProperties'
import { parseReferencableThing } from './referenceThing'

export const completeWebPageStructuredData = ({
    item,
    imagesMeta,
    organizationSchema,
    // asPath,
    override // basePath
}: CompleteStructuredDataParserProps): WebPage => {
    const { structuredData = parseWebPage(item), name: itemName } = item
    const {
        parsed: { name: sdName, description: sdDescription, ...parsedStructuredData } // data: intermediateData
    } = structuredData as ProcessedWebPageStructuredData
    const { title: name, description } = applyOverrideableSeoProperties(
        { title: itemName || sdName?.toString(), description: sdDescription?.toString() },
        override
    )
    // const { bodyMaxWords } = intermediateData
    // const url = parseSeoUrl(item, asPath, basePath)
    // @NOTE: rest of the properties are appended once frontend values are known
    return {
        ...parsedStructuredData,
        description,
        image: imagesMeta?.[0]?.url,
        thumbnailUrl: imagesMeta?.[0]?.url,
        publisher: parseReferencableThing(organizationSchema),
        name
        // primaryImageOfPage: imagesMeta?.[0]?.url,
        // mainEntityOfPage: url
    }
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const parseWebPage = (item, summary = false, bodyMaxWords = 100): ProcessedWebPageStructuredData => {
    const { title, description, publishDate, updateDate } = parseBasicSchemaProperties(item)
    const data = {}
    const parsed: WebPage = {
        // image: imageUrl,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        '@type': 'WebPage',
        name: title,
        headline: title,
        description,
        inLanguage: 'Croatian',
        // thumbnailUrl: imageUrl,
        dateCreated: publishDate,
        datePublished: publishDate,
        dateModified: updateDate,
        // keywords,
        about: description
    }

    return {
        data,
        parsed
    }
}

export default parseWebPage
