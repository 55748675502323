import { Organization, WebSite } from 'schema-dts'

import { completeStructureData, completeWebMetadata } from '../../../helpers/seo'
import { EntityElastic } from '../../../types/entity'
import { CompleteMetaData, ImageMeta, PagedItemSeo } from '../../../types/seo'
import { OverrideItem } from '../../../types/seo/SeoOverride'

interface CompleteSeoFromDataProps {
    item: any
    imagesMeta: ImageMeta[]
    deviceType?: string
    organization: Organization
    website: WebSite
    page?: number
    listItems?: EntityElastic[] | null
    asPath?: string
    pagedItem?: PagedItemSeo | null
    override?: Partial<OverrideItem>
    basePath?: string
}

const completeSeoFromData = ({
    item,
    imagesMeta,
    deviceType = 'desktop',
    organization: organizationSchema,
    listItems,
    page,
    asPath,
    pagedItem,
    override,
    basePath,
    website
}: CompleteSeoFromDataProps): { structuredData: any; metadata: CompleteMetaData | undefined } => {
    const metadata = completeWebMetadata({
        item,
        imagesMeta,
        device: deviceType,
        page,
        asPath,
        pagedItem,
        override,
        basePath
    })
    const structuredData = completeStructureData(item)({
        item,
        imagesMeta,
        metadata,
        organizationSchema,
        page,
        listItems,
        asPath,
        pagedItem,
        override,
        basePath,
        websiteSchema: website
    })

    return { structuredData, metadata }
}
export default completeSeoFromData
