// @TODO: define tag taxon type
const tagsToSeo = tags =>
    tags
        ? tags.reduce((acc, tag) => {
              if (tag?.taxon?.title) {
                  acc.push(tag.taxon.title)
              }
              return acc
          }, [])
        : undefined

export default tagsToSeo
